<template>
  <v-container>
    <v-card>
      <v-card-title>Données de payement</v-card-title>
      <v-container>
        <v-form @submit.prevent="submitPaymentData">
          <v-container>
            <v-card>
              <v-card-title>PayPal</v-card-title>
              <v-card-text>
              <v-text-field
                v-model="paymentData.paypalClientData"
                label="Données du client PayPal"
                outlined
                required
              ></v-text-field>

              <v-text-field
                v-model="paymentData.paypalSecretKey"
                label="Clé secrète PayPal"
                outlined
                required
              ></v-text-field>
            </v-card-text>
            </v-card>
          </v-container>
          <v-divider class="my-4"></v-divider>
          <v-container>
            <v-card>
              <v-card-title>Stripe</v-card-title>
              <v-card-text>
              <v-text-field
                v-model="paymentData.stripePublicKey"
                label="Clé publique Stripe"
                outlined
                required
              ></v-text-field>

              <v-text-field
                v-model="paymentData.stripeSecretKey"
                label="Clé secrète Stripe"
                outlined
                required
              ></v-text-field>
            </v-card-text>
            </v-card>
          </v-container>
          <v-container>
            <v-btn type="submit" color="primary">Envoyer</v-btn>
          </v-container>
        </v-form>

        <v-alert
          v-if="responseMessage"
          type="success"
          dismissible
        >
          {{ responseMessage }}
        </v-alert>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import Constant from "@/utils/constants";

export default {
  data() {
    return {
      paymentData: {
        paypalClientData: "",
        paypalSecretKey: "",
        stripePublicKey: "",
        stripeSecretKey: "",
      },
      responseMessage: "",
      ownerId: null,
    };
  },
  created() {
    // Load ownerId from local storage
    const storedOwnerId = localStorage.getItem('ownerId');
    if (storedOwnerId) {
      this.ownerId = parseInt(storedOwnerId, 10);
      this.loadPaymentData(); // Load payment data based on ownerId
    } else {
      console.error('Owner ID not found in local storage');
    }
  },
  methods: {
    loadPaymentData() {
      // Check local storage first
      const storedPaymentData = localStorage.getItem(`paymentData_${this.ownerId}`);
      if (storedPaymentData) {
        this.paymentData = JSON.parse(storedPaymentData);
      } else {
        // Fetch from the server if not in local storage
        this.fetchPaymentData();
      }
    },
    fetchPaymentData() {
      if (this.ownerId === null) {
        console.error("Owner ID is not set.");
        return;
      }

      fetch(Constant.QORANI + `library/library/getPaymentData/${this.ownerId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(data => {
          // Assume the API returns payment data in the format expected
          this.paymentData = data;
          console.log("Payment Data:", this.paymentData);

          // Save the fetched payment data to local storage with a unique key
          localStorage.setItem(`paymentData_${this.ownerId}`, JSON.stringify(this.paymentData));
        })
        .catch(error => {
          console.error("Error:", error);
        });
    },
    submitPaymentData() {
      if (this.ownerId === null) {
        this.responseMessage = "L'ID du propriétaire n'est pas défini.";
        return;
      }

      fetch(Constant.QORANI + `user/editPaymentData/${this.ownerId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.paymentData),
      })
        .then(response => response.json())
        .then(data => {
          this.responseMessage = "Données de paiement mises à jour avec succès !";

          // Save the updated payment data to local storage with a unique key
          localStorage.setItem(`paymentData_${this.ownerId}`, JSON.stringify(this.paymentData));
        })
        .catch(error => {
          console.error("Error:", error);
          this.responseMessage = "Une erreur s'est produite lors de la mise à jour des données de paiement.";
        });
    },
  },
};
</script>

<style scoped>
/* Optional styling */
.payment-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-field {
  margin-bottom: 20px;
}

.submit-btn {
  display: block;
  width: 100%;
  margin-top: 20px;
}

.response-alert {
  margin-top: 20px;
}
</style>
